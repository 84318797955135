import $ from 'jquery';

require('jquery-ui');
require('../../js/plugins/multiSelect');

import {
    loadReportParameters,
    getOperatorReferencePeriod,
    updateReferencePeriod,
    buildPipedUrlParametersFromNameValuePairs
} from "../shared/report-parameters";
import {Toast} from "../shared/toast";

$(() => {
    app.DOM.form_input.filter('select#report_selector').on('change', (e) => {

        const report = $(e.target).val();
        if (report !== '') {
            loadReportParameters(report, {});
        } else {
            app.DOM.form('div#parameters_region').empty();
            $('button#generate_report').prop('disabled', true);
        }
    });

    app.DOM.form_input.filter('button#generate_report').click(generateReport);
});

async function generateReport(e) {
    e.preventDefault();

    const report = $('select#report_selector').val();
    const displayFormat = $('input[name=display_format]:checked').val();

    const params = $('div#parameters_region input,select,textarea').serializeArray();
    params.push({
        name: 'display',
        value: displayFormat
    });

    const urlParams = buildPipedUrlParametersFromNameValuePairs(params);
    let url = app.CACHE.URL_ADMIN + 'report/generator/generate/' + report + '/' + urlParams;

    if (displayFormat !== 'html') {
        try {
            const loadingIcon = $('#go-button-ico');
            app.DOM.form_input.filter('button#generate_report').removeClass('button-primary').attr('disabled', true).prop('disabled', true);
            loadingIcon.show();
            await PromisedFetch(url);
            Toast.success('Done - check your downloads.', null, { positionClass: 'toast-top-right' });
            app.DOM.form_input.filter('button#generate_report').removeClass('button-primary').attr('disabled', false).prop('disabled', false);
            loadingIcon.hide();
        } catch(err) {
            Toast.error('Something went wrong please try again later.');
        }
    } else {
        window.open(url);
    }
}

async function PromisedFetch(url) {
    return new Promise((resolve, reject) => {
        let filename = "";
        fetch(url).then((result) => {
            if (!result.ok) {
                throw Error(result.statusText);
            }

            // We are reading the *Content-Disposition* header for getting the original filename given from the server
            const header = result.headers.get('Content-Disposition');
            const parts = header.split(';');
            filename = parts[1].split('=')[1].replaceAll("\"", "");

            return result.blob();
        }).then((blob) => {
            if (blob != null) {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
                resolve();
            }

            reject();
        }).catch((err) => {
            console.log(err);
            reject();
        });
    })
}
